// web-kamero-full/src/reducers/pouchDBReducer.js

import {
	ACTION_ALBUM_CLICKED,
	ACTION_ALBUM_CREATED,
	ACTION_ALBUM_DELETE_FINISHED,
	ACTION_ALBUM_DELETE_STARTED,
	ACTION_ALBUM_DELETED,
	ACTION_ALBUM_GUEST_UPDATED,
	ACTION_ALBUM_HOME_UPDATED,
	ACTION_ALBUM_UPDATED,
	ACTION_API_ERROR_OCCURED,
	ACTION_EVENT_CLICKED,
	ACTION_EVENT_HOME_UPDATED,
	ACTION_EVENT_UPDATED,
	ACTION_EXPIRED_EVENTS_UPDATED,
	ACTION_EXPIRED_FACES_EVENTS_UPDATED,
	ACTION_GUEST_UPLOAD_REFRESHING,
	ACTION_MY_EVENTS_UPDATED,
	ACTION_PHOTO_COUNT_UPDATED,
	ACTION_PHOTOS_LOAD_ERROR,
	ACTION_PHOTOS_LOAD_SUCCESS,
	ACTION_PHOTOS_LOADING,
	ACTION_RESTORE_VIEW_STATE,
	ACTION_SAVE_VIEW_STATE,
	ACTION_SEARCH_EVENTS_UPDATED,
	ACTION_TOTAL_EVENTS_UPDATED,
	ACTION_UPDATE_CLICKED_ALBUM,
	ACTION_VISIBLE_ALBUMS_UPDATED,
	ACTION_VISIBLE_EVENT_UPDATED,
	ACTION_VISIBLE_PHOTOS_PAGE_UPDATED,
	ACTION_VISIBLE_PHOTOS_UPDATED
} from '../actions/action_types';
import {
	addAtIndexArray,
	insertUniqueItemArray,
	removeAtIndexArray,
	removeItemArray,
	updateAtIndexArray
} from './arrayFunctions';

const initialState = {
	searchedEvents: undefined,
	myEvents: undefined,
	totalEvents: undefined,
	eventPage: undefined,
	visibleEvent: undefined,
	visibleAlbums: undefined,
	currentPage: 1,
	clickedAlbum: undefined,
	clickedAlbumPhotoCount: undefined,
	photoCounts: {},
	deletingAlbums: [],
	expiredEvents: [],
	apiError: undefined,
	guestUploadsLoading: false,
	isPhotosLoading: false,
	photosError: null,
	photosLoadSuccess: false,
	visiblePhotos: [],
	savedViewState: null // To store view state for restoration
};

const pouchDB = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_SAVE_VIEW_STATE:
			return {
				...state,
				savedViewState: action.viewState
			};
		case ACTION_RESTORE_VIEW_STATE:
			// Only restore if we have a valid view state and are on the same event
			if (!action.viewState) return state;

			if (state.visibleEvent && state.visibleEvent.id === action.viewState.eventId) {
				// Find the album to restore
				const albumToRestore = state.visibleAlbums
					? state.visibleAlbums.find((album) => album.id === action.viewState.albumId)
					: null;

				return {
					...state,
					clickedAlbum: albumToRestore || state.clickedAlbum,
					currentPage: action.viewState.currentPage || state.currentPage
				};
			}
			return state;

		case ACTION_SEARCH_EVENTS_UPDATED:
			return {
				...state,
				searchedEvents: action.events
			};
		case ACTION_MY_EVENTS_UPDATED:
			return {
				...state,
				myEvents: action.events,
				eventPage: action.eventPage
			};
		case ACTION_TOTAL_EVENTS_UPDATED:
			return {
				...state,
				totalEvents: action.totalEvents
			};
		case ACTION_EVENT_UPDATED:
			return {
				...state,
				myEvents: updateAtIndexArray(state.myEvents, action.eventIndex, action.event)
			};
		case ACTION_VISIBLE_EVENT_UPDATED:
			return {
				...state,
				visibleEvent: action.event
			};
		case ACTION_EVENT_CLICKED:
			return {
				...state,
				visibleEvent: action.event,
				visibleAlbums: undefined
			};

		// Update the ACTION_EVENT_HOME_UPDATED case in pouchDBReducer.js

		case ACTION_EVENT_HOME_UPDATED:
			// Check if we should preserve the clicked album
			const shouldPreserveClickedAlbum =
				state.clickedAlbum &&
				// Only preserve if we have visible albums and the clicked album is among them
				action.eventHome.albums &&
				action.eventHome.albums.some((album) => album.id === state.clickedAlbum.id);

			// Preserve album order if we already have albums and the IDs match
			let updatedAlbums = action.eventHome.albums;
			if (
				state.visibleAlbums &&
				state.visibleAlbums.length > 0 &&
				action.eventHome.albums &&
				action.eventHome.albums.length > 0
			) {
				// Check if the same albums are present (just in different order)
				const currentIds = new Set(state.visibleAlbums.map((album) => album.id));
				const newIds = new Set(action.eventHome.albums.map((album) => album.id));

				// Only reorder if we have the same set of albums
				if (currentIds.size === newIds.size && [...currentIds].every((id) => newIds.has(id))) {
					// Create a map of new albums by ID for quick lookup
					const albumsMap = {};
					action.eventHome.albums.forEach((album) => {
						albumsMap[album.id] = album;
					});

					// Preserve the order from state.visibleAlbums but use updated album data
					updatedAlbums = state.visibleAlbums.map((album) => albumsMap[album.id]);
				}
			}

			return {
				...state,
				visibleAlbums: updatedAlbums,
				// Preserve clicked album if it exists and is still in the album list
				clickedAlbum: shouldPreserveClickedAlbum
					? state.clickedAlbum
					: action.eventHome.albumHome
					? action.eventHome.albumHome.album
					: undefined,
				// Keep album photo count if preserving clicked album
				clickedAlbumPhotoCount: shouldPreserveClickedAlbum
					? state.clickedAlbumPhotoCount
					: action.eventHome.albumHome
					? action.eventHome.albumHome.albumPhotoCount
					: undefined,
				// Only update photos if not preserving clicked album
				visiblePhotos: shouldPreserveClickedAlbum
					? state.visiblePhotos
					: action.eventHome.albumHome
					? action.eventHome.albumHome.visiblePhotos
					: [],
				// Preserve current page when preserving album
				currentPage: shouldPreserveClickedAlbum ? state.currentPage : 1
			};
		case ACTION_ALBUM_HOME_UPDATED:
			return {
				...state,
				visiblePhotos: action.albumHome.visiblePhotos,
				currentPage: 1
			};
		case ACTION_ALBUM_GUEST_UPDATED:
			return {
				...state,
				visiblePhotos: action.guestAlbum.photos,
				currentPage: 1
			};
		case ACTION_VISIBLE_ALBUMS_UPDATED:
			return {
				...state,
				visibleAlbums: action.albums
			};
		case ACTION_ALBUM_CREATED:
			return {
				...state,
				visibleAlbums: addAtIndexArray(
					state.visibleAlbums,
					state.visibleAlbums.length,
					action.album
				)
			};
		case ACTION_ALBUM_UPDATED:
			return {
				...state,
				visibleAlbums: updateAtIndexArray(state.visibleAlbums, action.albumIndex, action.album)
			};
		case ACTION_ALBUM_DELETED:
			return {
				...state,
				visibleAlbums: removeAtIndexArray(state.visibleAlbums, action.albumIndex)
			};
		case ACTION_ALBUM_CLICKED:
			return {
				...state,
				clickedAlbum: action.album,
				visiblePhotos: [],
				currentPage: 1
			};
		case ACTION_UPDATE_CLICKED_ALBUM:
			return {
				...state,
				clickedAlbum: action.album
			};
		case ACTION_PHOTO_COUNT_UPDATED:
			return {
				...state,
				photoCounts: action.photoCounts
			};
		case ACTION_ALBUM_DELETE_STARTED: {
			return {
				...state,
				deletingAlbums: insertUniqueItemArray(state.deletingAlbums, action.albumId)
			};
		}
		case ACTION_ALBUM_DELETE_FINISHED: {
			return {
				...state,
				deletingAlbums: removeItemArray(state.deletingAlbums, action.albumId)
			};
		}
		case ACTION_API_ERROR_OCCURED: {
			return {
				...state,
				apiError: action.error
			};
		}
		case ACTION_GUEST_UPLOAD_REFRESHING: {
			return {
				...state,
				guestUploadsLoading: action.value
			};
		}
		case ACTION_EXPIRED_EVENTS_UPDATED:
			return {
				...state,
				expiredEvents: action.expiredEvents
			};
		case ACTION_EXPIRED_FACES_EVENTS_UPDATED:
			return {
				...state,
				expiredFacesEvents: action.expiredFacesEvents
			};
		case ACTION_PHOTOS_LOADING:
			return {
				...state,
				isPhotosLoading: action.isLoading,
				photosError: null,
				photosLoadSuccess: false
			};

		case ACTION_PHOTOS_LOAD_ERROR:
			return {
				...state,
				photosError: action.error,
				photosLoadSuccess: false
			};

		case ACTION_PHOTOS_LOAD_SUCCESS:
			return {
				...state,
				photosLoadSuccess: true,
				photosError: null,
				visiblePhotos: action.photos
			};
		case ACTION_VISIBLE_PHOTOS_UPDATED:
			return {
				...state,
				visiblePhotos: action.photos || [],
				photosLoadSuccess: Array.isArray(action.photos) && action.photos.length > 0
			};

		case ACTION_VISIBLE_PHOTOS_PAGE_UPDATED:
			return {
				...state,
				currentPage: action.currentPage,
				visiblePhotos: action.photos || [],
				photosLoadSuccess: Array.isArray(action.photos) && action.photos.length > 0
			};
		default:
			return state;
	}
};

export default pouchDB;
