// src/utils/imageUrlGenerator.js

import { IS_DEVELOPMENT } from '../config';

export const MEDIA_BASE_URL = 'https://media.kamero.ai';

/**
 * Generates a URL for the Kamero Image API
 * @param {Object} config - Configuration object for the image
 * @returns {string} Generated URL for the image
 * @private
 */
const generateImageUrl = (config) => {
	const jsonString = JSON.stringify(config);
	const encodedConfig = btoa(jsonString);
	return `${MEDIA_BASE_URL}/${encodedConfig}`;
};

/**
 * Creates base config for image URL generation
 * @param {string} s3Key - S3 key of the image
 * @param {Object} edits - Optional edits configuration
 * @returns {Object} Base configuration object
 * @private
 */
const createBaseConfig = (s3Key, edits = null) => {
	const config = {
		key: s3Key,
		...(edits && { edits })
	};

	if (IS_DEVELOPMENT) {
		config.bucket = 'kamero-dev';
	}

	return config;
};

/**
 * Generates a URL for a thumbnail image
 * @param {string} s3Key - S3 key of the image
 * @returns {string} Generated thumbnail URL
 */
export const generateThumbnailUrl = (s3Key) => {
	const config = createBaseConfig(s3Key, {
		resize: {
			width: 480
		}
	});
	return generateImageUrl(config);
};

/**
 * Generates a URL for a full-size image
 * @param {string} s3Key - S3 key of the image
 * @returns {string} Generated full-size image URL
 */
export const generateFullImageUrl = (s3Key) => {
	const config = createBaseConfig(s3Key);
	return generateImageUrl(config);
};

/**
 * Generates a URL for a 1080w image
 * @param {string} s3Key - S3 key of the image
 * @returns {string} Generated 1080w URL
 */
export const generate1080wUrl = (s3Key) => {
	const config = createBaseConfig(s3Key, {
		resize: {
			width: 1080
		}
	});
	return generateImageUrl(config);
};

/**
 * Generates a URL for an event cover image
 * @param {string} s3Key - S3 key of the image
 * @returns {string} Generated cover image URL
 */
export const generateCoverImageUrl = (s3Key) => {
	// If s3Key is null or empty, return the default cover image
	if (!s3Key) {
		return generateImageUrl({
			key: 'logos/no-event-cover.jpeg',
			bucket: 'kamero-public',
			edits: {
				resize: {
					width: 1080,
					height: 720,
					fit: 'cover'
				}
			}
		});
	}

	const config = createBaseConfig(s3Key, {
		resize: {
			width: 1080,
			height: 720,
			fit: 'cover'
		}
	});
	return generateImageUrl(config);
};

/**
 * Generates a URL for a video poster image
 * @param {string} s3Key - S3 key of the image
 * @returns {string} Generated video poster URL
 */
export const generateVideoPosterUrl = (s3Key) => {
	const config = createBaseConfig(s3Key, {
		resize: {
			width: 1080,
			fit: 'cover'
		}
	});
	return generateImageUrl(config);
};

/**
 * Generates a URL for a profile thumbnail image
 * @param {string} s3Key - S3 key of the image
 * @returns {string} Generated profile thumbnail URL
 */
export const generateProfileThumbnailUrl = (s3Key) => {
	if (!s3Key) return '';

	const config = createBaseConfig(s3Key, {
		resize: {
			width: 320,
			height: 320,
			fit: 'cover'
		}
	});
	return generateImageUrl(config);
};
