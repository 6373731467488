// web-kamero-full/src/actions/navigationActions.js

import { ACTION_RESTORE_VIEW_STATE, ACTION_SAVE_VIEW_STATE } from './action_types';

/**
 * Save the current view state for later restoration
 * @param {object} viewState - The current view state to save
 * @returns {object} The action object
 */
export const saveViewState = (viewState) => ({
	type: ACTION_SAVE_VIEW_STATE,
	viewState
});

/**
 * Restore a previously saved view state
 * @param {object} viewState - The view state to restore
 * @returns {object} The action object
 */
export const restoreViewState = (viewState) => ({
	type: ACTION_RESTORE_VIEW_STATE,
	viewState
});

/**
 * Store the current view state in sessionStorage
 * @param {string} eventId - The current event ID
 * @param {string} albumId - The current album ID
 * @param {number} currentPage - The current page number
 */
export const storeViewState = (eventId, albumId, currentPage) => {
	try {
		const viewState = {
			eventId,
			albumId,
			currentPage,
			timestamp: Date.now()
		};

		sessionStorage.setItem('lastViewState', JSON.stringify(viewState));

		return saveViewState(viewState);
	} catch (error) {
		console.error('Error storing view state:', error);
		return { type: 'ERROR_STORING_VIEW_STATE' };
	}
};

/**
 * Retrieve the previously saved view state from sessionStorage
 * @returns {object|null} The saved view state or null if not found
 */
export const getStoredViewState = () => {
	try {
		const storedState = sessionStorage.getItem('lastViewState');
		if (!storedState) return null;

		const viewState = JSON.parse(storedState);

		// Check if the state is still valid (less than 5 minutes old)
		if (Date.now() - viewState.timestamp > 5 * 60 * 1000) {
			// State is too old, clear it
			sessionStorage.removeItem('lastViewState');
			return null;
		}

		return viewState;
	} catch (error) {
		console.error('Error retrieving view state:', error);
		return null;
	}
};

/**
 * Clear any stored view state
 */
export const clearViewState = () => {
	try {
		sessionStorage.removeItem('lastViewState');
	} catch (error) {
		console.error('Error clearing view state:', error);
	}

	return { type: 'CLEAR_VIEW_STATE' };
};
