// web-kamero-full/src/actions/action_types.js

// Auth Actions
export const ACTION_LOGIN_ERROR = 'auth/login/error';
export const ACTION_LOGOUT_SUCCESS = 'auth/logout/success';
export const ACTION_UPDATE_USER_PROFILE = 'auth/update/profile';
export const ACTION_UPDATE_SUBSCRIPTION = 'auth/update/subscription';

// Subscription Actions
export const ACTION_FETCH_SUBSCRIPTION_PLANS_REQUEST = 'subscription/fetch_plans/request';
export const ACTION_FETCH_SUBSCRIPTION_PLANS_SUCCESS = 'subscription/fetch_plans/success';
export const ACTION_FETCH_SUBSCRIPTION_PLANS_FAILURE = 'subscription/fetch_plans/failure';

export const ACTION_PURCHASE_SUBSCRIPTION_REQUEST = 'subscription/purchase/request';
export const ACTION_PURCHASE_SUBSCRIPTION_SUCCESS = 'subscription/purchase/success';
export const ACTION_PURCHASE_SUBSCRIPTION_FAILURE = 'subscription/purchase/failure';

// Event Actions
export const ACTION_EVENT_CREATED = 'event/created';

// Navigation State Persistence
export const ACTION_SAVE_VIEW_STATE = 'navigation/save_view_state';
export const ACTION_RESTORE_VIEW_STATE = 'navigation/restore_view_state';

// Add any other existing action types here...
export const ACTION_ALBUM_CLICKED = 'ACTION_ALBUM_CLICKED';
export const ACTION_ALBUM_CREATED = 'ACTION_ALBUM_CREATED';
export const ACTION_ALBUM_DELETED = 'ACTION_ALBUM_DELETED';
export const ACTION_ALBUM_DELETE_FINISHED = 'ACTION_ALBUM_DELETE_FINISHED';
export const ACTION_ALBUM_DELETE_STARTED = 'ACTION_ALBUM_DELETE_STARTED';
export const ACTION_ALBUM_GUEST_UPDATED = 'ACTION_ALBUM_GUEST_UPDATED';
export const ACTION_ALBUM_HOME_UPDATED = 'ACTION_ALBUM_HOME_UPDATED';
export const ACTION_ALBUM_UPDATED = 'ACTION_ALBUM_UPDATED';

// API and error action types
export const ACTION_API_ERROR_OCCURED = 'ACTION_API_ERROR_OCCURED';

// Event-related action types
export const ACTION_EVENT_CLICKED = 'ACTION_EVENT_CLICKED';
export const ACTION_EVENT_HOME_UPDATED = 'ACTION_EVENT_HOME_UPDATED';
export const ACTION_EVENT_UPDATED = 'ACTION_EVENT_UPDATED';
export const ACTION_EXPIRED_EVENTS_UPDATED = 'ACTION_EXPIRED_EVENTS_UPDATED';
export const ACTION_EXPIRED_FACES_EVENTS_UPDATED = 'ACTION_EXPIRED_FACES_EVENTS_UPDATED';
export const ACTION_GUEST_UPLOAD_REFRESHING = 'ACTION_GUEST_UPLOAD_REFRESHING';
export const ACTION_MY_EVENTS_UPDATED = 'ACTION_MY_EVENTS_UPDATED';
export const ACTION_SEARCH_EVENTS_UPDATED = 'ACTION_SEARCH_EVENTS_UPDATED';
export const ACTION_TOTAL_EVENTS_UPDATED = 'ACTION_TOTAL_EVENTS_UPDATED';

// Photo-related action types
export const ACTION_PHOTO_COUNT_UPDATED = 'ACTION_PHOTO_COUNT_UPDATED';
export const ACTION_PHOTOS_LOAD_ERROR = 'ACTION_PHOTOS_LOAD_ERROR';
export const ACTION_PHOTOS_LOAD_SUCCESS = 'ACTION_PHOTOS_LOAD_SUCCESS';
export const ACTION_PHOTOS_LOADING = 'ACTION_PHOTOS_LOADING';

// UI and navigation action types
export const ACTION_UPDATE_CLICKED_ALBUM = 'ACTION_UPDATE_CLICKED_ALBUM';
export const ACTION_VISIBLE_ALBUMS_UPDATED = 'ACTION_VISIBLE_ALBUMS_UPDATED';
export const ACTION_VISIBLE_EVENT_UPDATED = 'ACTION_VISIBLE_EVENT_UPDATED';
export const ACTION_VISIBLE_PHOTOS_PAGE_UPDATED = 'ACTION_VISIBLE_PHOTOS_PAGE_UPDATED';
export const ACTION_VISIBLE_PHOTOS_UPDATED = 'ACTION_VISIBLE_PHOTOS_UPDATED';

// Login and authentication action types
export const ACTION_LOGGED_OUT = 'ACTION_LOGGED_OUT';
export const ACTION_LOGIN_FAILED = 'ACTION_LOGIN_FAILED';
export const ACTION_LOGIN_START_ON_BUTTON_TAP = 'ACTION_LOGIN_START_ON_BUTTON_TAP';
export const ACTION_LOGIN_SUCCESS = 'ACTION_LOGIN_SUCCESS';
export const ACTION_NEEDS_LOGIN = 'ACTION_NEEDS_LOGIN';
export const ACTION_CLEAR_LOGIN_ERR = 'ACTION_CLEAR_LOGIN_ERR';

// Profile-related action types
export const ACTION_PROFILE_CREATION_FAILED = 'ACTION_PROFILE_CREATION_FAILED';
export const ACTION_PROFILE_CREATION_STARTED = 'ACTION_PROFILE_CREATION_STARTED';
export const ACTION_PROFILE_CREATION_SUCCESS = 'ACTION_PROFILE_CREATION_SUCCESS';
export const ACTION_PROFILE_EDIT_FAILED = 'ACTION_PROFILE_EDIT_FAILED';
export const ACTION_PROFILE_EDIT_STARTED = 'ACTION_PROFILE_EDIT_STARTED';
export const ACTION_PROFILE_EDIT_SUCESS = 'ACTION_PROFILE_EDIT_SUCESS';
export const ACTION_PROFILE_NOT_CREATED = 'ACTION_PROFILE_NOT_CREATED';
export const ACTION_GET_PROFILE_DATA = 'ACTION_GET_PROFILE_DATA';
export const GET_PROFILE_DATA_FAILED = 'GET_PROFILE_DATA_FAILED';
export const GET_PROFILE_DATA_STARTED = 'GET_PROFILE_DATA_STARTED';
export const UPDATE_COVER_PHOTO_LINK = 'UPDATE_COVER_PHOTO_LINK';
export const UPDATE_PROFILE_IF_CREATED = 'UPDATE_PROFILE_IF_CREATED';
export const UPDATE_PROFILE_PHOTO_LINK = 'UPDATE_PROFILE_PHOTO_LINK';

// Replication action types
export const ACTION_REPLICATION_CONFIGURED = 'ACTION_REPLICATION_CONFIGURED';
export const ACTION_CONTINUOUS_REPLICATION_STATE_CHANGED =
	'ACTION_CONTINUOUS_REPLICATION_STATE_CHANGED';

// Upload action types
export const ACTION_UPLOAD_ERROR = 'ACTION_UPLOAD_ERROR';
export const ACTION_UPLOAD_FINISHED = 'ACTION_UPLOAD_FINISHED';
export const ACTION_UPLOAD_PROGRESS_UPDATED = 'ACTION_UPLOAD_PROGRESS_UPDATED';
export const ACTION_UPLOAD_STARTED = 'ACTION_UPLOAD_STARTED';
export const ACTION_PHOTO_UPLOAD_FAILED = 'ACTION_PHOTO_UPLOAD_FAILED';
export const ACTION_PHOTO_UPLOAD_STARTED = 'ACTION_PHOTO_UPLOAD_STARTED';

// Post and videolink action types
export const ACTION_POST_CREATED = 'ACTION_POST_CREATED';
export const ACTION_POST_DELETED = 'ACTION_POST_DELETED';
export const ACTION_POST_UPDATED = 'ACTION_POST_UPDATED';
export const ACTION_VISIBLE_POSTS_UPDATED = 'ACTION_VISIBLE_POSTS_UPDATED';
export const ACTION_VIDEOLINK_CREATED = 'ACTION_VIDEOLINK_CREATED';
export const ACTION_VIDEOLINK_DELETED = 'ACTION_VIDEOLINK_DELETED';
export const ACTION_VIDEOLINK_UPDATED = 'ACTION_VIDEOLINK_UPDATED';
export const ACTION_VISIBLE_VIDEOLINKS_UPDATED = 'ACTION_VISIBLE_VIDEOLINKS_UPDATED';

// Billing and invoice action types
export const ACTION_BILLING_DETAILS_CREATED = 'ACTION_BILLING_DETAILS_CREATED';
export const ACTION_BILLING_DETAILS_FETCHED = 'ACTION_BILLING_DETAILS_FETCHED';
export const ACTION_BILLING_DETAILS_UPDATED = 'ACTION_BILLING_DETAILS_UPDATED';
export const ACTION_INVOICE_DOWNLOAD_FAILURE = 'ACTION_INVOICE_DOWNLOAD_FAILURE';
export const ACTION_INVOICE_DOWNLOAD_SUCCESS = 'ACTION_INVOICE_DOWNLOAD_SUCCESS';

// Event creation action types
export const ACTION_CREATE_EVENT_PACK_CHOSEN = 'ACTION_CREATE_EVENT_PACK_CHOSEN';
export const ACTION_CREATE_EVENT_PAYMENT_AUTHORIZED = 'ACTION_CREATE_EVENT_PAYMENT_AUTHORIZED';
export const ACTION_CREATE_EVENT_WITH_NAME = 'ACTION_CREATE_EVENT_WITH_NAME';

// UI and navigation action types
export const ACTION_UPDATE_CURRENT_PAGE = 'ACTION_UPDATE_CURRENT_PAGE';
export const ACTION_UPDATE_EVENT_ID = 'ACTION_UPDATE_EVENT_ID';

// Profile and company details action types
export const ACTION_UPDATE_ABOUT_COMPANY = 'ACTION_UPDATE_ABOUT_COMPANY';
export const ACTION_UPDATE_BRAND_COLOR = 'ACTION_UPDATE_BRAND_COLOR';
export const ACTION_UPDATE_COMPANY_NAME = 'ACTION_UPDATE_COMPANY_NAME';
export const ACTION_UPDATE_PERSONAL_DETAILS = 'ACTION_UPDATE_PERSONAL_DETAILS';
export const ACTION_UPDATE_PHONE_NUMBER = 'ACTION_UPDATE_PHONE_NUMBER';
export const ACTION_UPDATE_SOCIAL_LINKS = 'ACTION_UPDATE_SOCIAL_LINKS';
export const ACTION_UPDATE_WHATSAPP_DETAILS = 'ACTION_UPDATE_WHATSAPP_DETAILS';
